
@tailwind base;
@tailwind components;
@tailwind utilities;



.RSPBprogressBar {
    min-width: 10em;
    height: 2px;
    width: 30%;
    line-height: 1;
    border-radius: 10px;
    position: relative;
  
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
    margin: 20px auto;
}

.RSPBprogressBar .RSPBstep {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    position: absolute;
    transform: translateX(-50%);
    transition-property: all;
    transition-timing-function: ease;
}

.RSPBprogressBar .RSPBprogressBarText {
    color: white;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* .RSPBprogressBar .RSPBprogression {
    position: absolute;
    transition: width 0.3s ease;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    background: rgb(197,109,134);
    background: linear-gradient(90deg, rgba(197,109,134,1) 35%, rgba(254,117,131,1) 100%);
    z-index: -1;
}  */

@media screen and (max-width: 480px) {
    .indexedStep{
        width: 15px;
        height: 15px;
        font-size: 6px;
    }
}